import React, { Component } from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {MetaData} from "../components/meta"

import VideoCard from "../components/video-card"

class VideosPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      videoId: "",
      videoTitle: "",
      videoSearch: "",
    }
  }

  updateInputValue(value) {
    this.setState({ videoSearch: value })
  }

  openYoutubeSearch(){
    const win = window.open(`https://www.youtube.com/user/pompeo1212/search?query=${this.state.videoSearch}`, '_blank');
    this.setState({'videoSearch':''})
    win.focus();
  }

  render() {
    const videos = this.props.data.allYoutubeVideo.edges
    const videoPrincipal = videos[0]

    const location = {
      pathname: "/videos/",
      search: "",
      hash: "",
    }

    return (
      <Layout>
        <MetaData location={location} type="series" />
        {/* <div className="row">
					<div className="col-12"><h2>#vídeos</h2></div>
				</div> */}
        <div className="row pt-3 justify-content-center">
          {/* noticia principal */}
          <div className="col-md-10">
            <Link
              to={`/videos/${videoPrincipal.node.slug}`}
              className="btn"
              role="button"
            >
              <div className="row ">
                <div className="col-md-6 p-0">
                  <img
                    src={videoPrincipal.node.thumbnails.high.url}
                    className="img-fluid"
                    alt={videoPrincipal.node.title}
                  />
                </div>
                <div className="col-md-6">
                  <h6 className="text-left tag-title">
                    {videoPrincipal.node.publishedAt}
                  </h6>
                  <h4 className="text-left">{videoPrincipal.node.title}</h4>
                  <p className="limit linkPrincipal">
                    {videoPrincipal.node.description}
                  </p>
                </div>
              </div>
            </Link>
            <hr />

            <div className="col-md-12 py-2 px-4 bg-light rounded">
              <a
                className="text-muted text-decoration-none"
                href="https://www.youtube.com/user/pompeo1212"
                target="_blank"
                rel="noopener noreferrer"
                title="Youtube"
              >
                <h2 className="mt-3">
                  <strong>
                    <span className="text-danger">
                      <i>
                        {/* <FontAwesomeIcon icon={["fab", "youtube"]} /> */}
                      </i>
                    </span>{" "}
                    YouTube
                  </strong>
                </h2>
              </a>
              <div className="col-12">
                <p>
                  Para mais vídeos e informações busque em meu canal do YouTube
                </p>
              </div>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="busque mais vídeos"
                  aria-label="Busca"
                  aria-describedby="button-addon2"
                  value={this.state.videoSearch}
                  onChange={e=>this.updateInputValue(e.target.value)}
                />
                <div className="input-group-append">
                 
                    <button
                      className="btn btn-outline-secondary"
                      type="button"
                      id="button-addon2"
                      onClick={this.openYoutubeSearch.bind(this)}
                    >
                      Buscar
                    </button>
                
                </div>
              </div>
            </div>

            <div className="row mt-2">
              {videos.slice(1, videos.length).map(({ node }, index) => (
                <div key={index} className="col-sm-4 py-2">
                  <VideoCard
                    key={node.videoId}
                    title={node.title}
                    description={node.description}
                    imageSrc={node.thumbnails.medium.url}
                    publishedAt={node.publishedAt}
                    slug={node.slug}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export const query = graphql`
  query YouTubeVideos {
    allYoutubeVideo(sort: { order: DESC, fields: publishedAt }, limit: 15) {
      edges {
        node {
          ...YoutubeVideoFields
        }
      }
    }
  }
`

export default VideosPage
