import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `StaticQuery`: https://gatsby.dev/staticquery
 */

const VideoCard = ({ title, description, imageSrc, publishedAt, slug }) => (
  <div className="card h-100 ">
    <Link className="text-reset " to={`/videos/${slug}`}>
      <img
        className="card-img-top"
        src={imageSrc}
        alt="Imagem de capa do card"
      />
    </Link>
    <div className="card-body h-100">
      <Link className="text-reset " to={`/videos/${slug}`}>
        <h5 className="card-title text-left">{title}</h5>
      </Link>
    </div>
    <div className="card-footer">
      <small>
        <strong>
          <p className="text-left">{publishedAt}</p>
        </strong>
      </small>
    </div>
  </div>
)
export default VideoCard

VideoCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  imageSrc: PropTypes.string.isRequired,
  publishedAt: PropTypes.string.isRequired,
}
